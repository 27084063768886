/* BASE SETUP */


body{
	/*width: 100%;*/
	height: 100%;
}

body {
  
  font-family: "Gill Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url("BSBG1.jpg");
  background-attachment: fixed;
  background-size: 100%;

  font-weight:600;
  text-shadow: 1px 1px black;
  font-size:18px;
  
}

#wrapper{
  width: 100%;
  height: 100%;
  overflow: scroll;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.tinted-background {
  position: relative;
  background-color: rgba(0, 0, 0, 0.6); /* Adjust the opacity and color as desired */
  color: #fff; /* Text color */
  padding: 20px;
  border-radius: 20px;
  width:900px;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:20px;
}


a, a:visited {
  color: white;
}

#invite {
  font-family: "Snell roundhand";
  font-style: italic;
  font-size: 32px;
  font-weight:800;
  text-shadow: 2px 2px black;
  
}

.toc-list {
  margin-top:10px;
}

#upsign {
  text-decoration: line-through;
}

#campinfo h2, #contribution h2, #signup h2, #schedule h2 {
  text-align: center;
}

#flush a:hover {
  color:red;
}

#greenhouse a:hover {
  color: green;
}

#praxis a:hover {
  color: purple;
}


.title *{
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

#bs {
  background-image: url("BSGlitter.gif");
  height:73px;
  width:657px;
  /*width:100%;
  height: 100%;*/
  margin-left: auto;
  margin-right: auto;
}


.title{
  margin-bottom:50px;
}

h2 {
  margin-bottom:0px;
  font-size: 28px;
}

hr {
  height: 2px;
  background:white;
  border:none;
}

h3 {
  font-size:22px;
  font-weight:700;
  
}
#campinfo h3 {
  text-decoration: underline;
}

ul ul {
 
  margin-bottom:10px;
}

li {
  margin-bottom: 5px;
}

.content {
  text-align: left;
 
}

.row {
  display: flex;
  margin-bottom:30px;
}

.column {
  flex: 1;
  margin-left:5%;
  margin-right:5%;
}

.column img {
  max-width: 350px;
}

.column.sm {
  flex: 30%;
  
  margin-top:10%;
}
.column.lg {
  flex: 75%;
  
}

.column.sm img {
  width:120%;
  margin-left:-10%;
  opacity: 100%;
}

.column.opac img{
  opacity: 100%;
}

.column.opac2 img{
  opacity: 80%;
}

.imgbanner {
  width:100%;
  opacity: 75%;
  border-radius: 10px;
  
}

.info img {
  width:100%;
  border-radius: 50%;
  opacity: 50%;
}


#contribution, #signup{
  
  margin-left:auto;
  margin-right:auto;
}


.note {
  background-color: #fff;
  color:black;
  text-shadow: none;
  margin-bottom:10px;
}

#form {
  
  margin-bottom:20px;
}

a.button {
  background-color: #4CAF50; /* Green */
  display:block;
  
  border: none;
  color: white;
  padding: 15px 32px;
  border-radius: 30px;
  text-align: center;
  text-decoration: none;
  width:120px;
  margin-top:20px;
  margin-left:auto;
  margin-right:auto;
  font-size: 26px;
}

a.button:hover {
  background-color: white;
  color:#4CAF50;
}

#schedule img {
  max-width: 100%;
}

#oxy a:hover {
  color: lightskyblue;
}



@media only screen and (min-width:976px) {
  #lights-img{
    margin-top:5%;
  }
  #music-img {
    margin-top:6%;
  }

  #sun-img {
    width:140%;
    
  }

  #food-img {
    max-width: 110%;
    width:110%;
    margin-top:0%;
    margin-bottom:-5%
  }

  #cabin-img {
    margin-top:20%;
    
  }

  #shroom-img {
    margin-top:2%;
    margin-bottom:-2%;
  }
  #oxy {
    width:105%;
  }
  
}

@media only screen and (max-width: 975px) {

  .tinted-background {
    width: 90%;
  }


  .title img {
    max-width: 100%;
  }
  
  .column img, .column.sm img, .column.lg img {
    display:flex;
    width:100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
    text-align: center;
  }

  .row {
    flex-direction: column; /* Stack columns vertically */
  }

  .column {
    flex: 100%;
    width:100%;
    margin-left:0;
  }

  .column img {
    margin-top: 10%;
  }

  .row.rev .column:last-child
   {
    order: -1;
    
  }

 #bs {
    background-image: url("BSGlitter2.gif");
    width:70vw;
    height:11vw;
    background-size: contain;
  }

  #ttt {
    background-image: url("2023Glitter.gif");
    width:18vw;
    height:9vw;
    /*width:100%;
    height: 100%;*/
    margin-left: auto;
    margin-right: auto;
    background-size:contain;
  }



  .reg img {
    margin-top:-6%;
  }
  #lights-img{
    margin-top:1%;
    margin-bottom:-3%;
  }
  
  #music-img {
    margin-top:2%;
    margin-bottom:-7%;
  }

  #sun-img {
    margin-top: -7%;
    margin-bottom: -9%;
  }

  #food-img {
    margin-top:-8%;
    margin-bottom:-5%
  }

  #cabin-img {
    margin-top:2%;
    margin-bottom:-5%;
  }

  #shroom-img {
    margin-top:2%;
    margin-bottom:-2%;
  }

}



@media only screen and (max-width: 650px) {
 body {
  background-image: url("BSBG2.jpg");
  background-attachment: scroll;
  background-size: 100% 200%;
 }

#bs {
  background-image: url("BSGlitter2.gif");
  width:85vw;
  height:14vw;
  background-size: contain;
}
}















